/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Module Background
 * 
*/

import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import BackgroundVideo from "../media/BackgroundVideo";

export default class ModuleBackground extends Component
{
    static propTypes = {
        type: PropTypes.string,
        className: PropTypes.string,
        image: PropTypes.object,
        imageAlt: PropTypes.string,
        hasVideo: PropTypes.bool,
        device: PropTypes.any,                  // DeviceTool: get screenSize()
        video: PropTypes.shape({
            desktop: PropTypes.string, 
            mobile: PropTypes.string, 
            loop: PropTypes.bool,
            autoLoad: PropTypes.bool,
            autoPlay: PropTypes.bool,
            delay: PropTypes.number,
        }),
        showGradient: PropTypes.bool,
        gradColor: PropTypes.string,
        onMounted: PropTypes.func,
        onLoaded: PropTypes.func,
        onTime: PropTypes.func,
        imageFadeIn: PropTypes.bool,
        videoFadeIn: PropTypes.bool,
    }
    
    static defaultProps = {
        type: "module-background",
        className: "",
        imageAlt: "Background Hero",
        hasVideo: false,
        showGradient: true,
        gradColor: "blue",
        imageFadeIn: true,
        videoFadeIn: true,
    }

    constructor(props)
    {
        super(props);

        this.imageRef = createRef();
        this.videoRef = createRef();

        this._tween = null;
    }

    componentDidMount()
    {
        const { device } = this.props;

        if (this.imageRef && this.imageRef.current)
        {
            if (device.width > 370)
            {
                window.requestAnimationFrame(() => {
                    this._tween = gsap.from(this.imageRef.current, { opacity: 0, duration: 1, ease: "cubic.out" });
                })
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { video, image } = this.props;
        const { video:prevVideo, image:prevImage } = nextProps;

        // if (video.autoLoad !== nextProps.video.autoLoad) return true;

        const update = video !== prevVideo || image !== prevImage;
        if (update) return true;

        return false;
    }

    onMeta(params)
    {
        const { videoFadeIn:fadeIn, onLoaded, video: { delay } } = this.props;

        onLoaded && onLoaded(params);

        // if we have fade and no delay, show video
        if (fadeIn && delay <= 0) 
        {
            const { container } = params;
            this.fadeInVideo(container.current);
        }
    }

    onDelay(params)
    {
        const { container } = params;
        this.fadeInVideo(container.current);
    }

    fadeInVideo(video)
    {
        this._tween = gsap.to(video, { delay: 1, opacity: 1, duration: 3, ease: "cubic.out" });
    }

    onTime(params)
    {
        const { onTime } = this.props;

        onTime && onTime(params);
    }

    componentWillUnmount()
    {
        if (this._tween) this._tween.kill();
        this._tween = null;
    }

    render()
    {
        const { 
            device,
            className,
            image, 
            imageAlt,
            video,
            showGradient: showG,
            gradColor
        } = this.props;

        const imageC = !image ? null : <img className="bg-img" src={image.src()} width={"1920px"} height={"1080px"} alt={imageAlt} />;
        let videoC = null, hasVideo = false;
        if (video) 
        {
            hasVideo = true;
            const { desktop, autoLoad, loop, delay } = video;

            videoC = <BackgroundVideo 
                ref={this.videoRef}
                device={device}
                autoLoad={autoLoad}
                autoPlay={true}
                classes="bg-layer bg-video-container parallax"
                videoClass="bg-video"
                desktop={desktop}
                loop={loop}
                delay={delay}
                scrnSize={false}
                isBackground={true}
                onTime={(params) => this.onTime(params)}
                onMeta={(params) => this.onMeta(params)}
                onDelay={(params) => this.onDelay(params)}
            />
        }

        const classes = classNames("module-background", className, { "has-video": hasVideo });
        const overlayCl = classNames("bg-gradient-placeholder");
        const bgCl = classNames("bg-overlay", gradColor, { "show-gradient": showG });

        return (
            <Fragment>
                <div className={classes}>
                    {imageC && <div ref={this.imageRef} className="bg-layer bg-im">{imageC}</div>}
                    {videoC}
                    {showG && <div className={overlayCl}>
                        <div className={bgCl}></div>
                    </div>}
                </div>
            </Fragment>
        )
    }
}