/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Scroll Down
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export default class ScrollDown extends Component
{
    static propTypes = {
        scrollRef: PropTypes.any,
        label: PropTypes.string,
    }
    
    static defaultProps = {
        label: "Scroll down"
    }

    constructor(props)
    {
        super(props);

        this.innerRef = null;
    }

    componentDidMount()
    {
        const { scrollRef } = this.props;

        scrollRef && scrollRef(this.innerRef);
    }

    render()
    {
        const { label } = this.props;

        return (
            <Fragment>
                <div ref={div => this.innerRef = div} className="scroll-down-container">
                    <div className="scroll-down-icon">
                        <div className="scroll-down-chevron">
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                            <div className="chevron"></div>
                        </div>
                        <span className="chevron-text">{label}</span>
                    </div>
                </div>
            </Fragment>
        )
    }
}