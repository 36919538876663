/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Text Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ImageLayer extends Component
{
    static propTypes = {
        device: PropTypes.any,                  // DeviceTool.screenSize
        type: PropTypes.string,
        classes: PropTypes.string,
        mobile: PropTypes.shape({
            order: PropTypes.number
        }),
        sendToBack: PropTypes.bool,
        backgroundColor: PropTypes.string,
        source: PropTypes.string,
        asset: PropTypes.any,                   // lazyload, string or object
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        author: PropTypes.shape({
            name: PropTypes.string, 
            theme: PropTypes.string,
            position: PropTypes.string,
            class: PropTypes.string
        }),
        hasLazyLoaded: PropTypes.bool
    }
    
    static defaultProps = {
        type: "ImageLayer",
        classes: "",
        mobile: {},
        alt: "",
        sendToBack: false,
        backgroundColor: null,
        author: null,
        hasLazyLoaded: false
    }

    render()
    {
        const { 
            device,
            classes,
            mobile,
            sendToBack,
            backgroundColor,
            source, alt, asset,
            x, y, width, height,
            author,
            hasLazyLoaded,
            children: ch
        } = this.props;

        // take care of children props
        let _preCh = [], newCh = [];
        if (ch)
        {
            if (Array.isArray(ch)) _preCh = ch; else _preCh = [ch];
            newCh = _preCh.map((child, idx) => 
            {
                return React.cloneElement(child, { key: idx, ...child.props });
            });
        }

        // const holder = "./images/placeholder" + ( device.isWebP() ? ".webp" : ".jpg");
        const phSrc = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`

        const { lazy, noexp } = asset;
        let styles = {}, innerStyles = {}, imageStyles = {};
        let imageProps = {};
        if (device.portrait)
        {
            styles = { backgroundColor }
            if (mobile && mobile.hasOwnProperty("order")) styles.order = mobile.order;

            const ratio = device.width / width;
            imageProps = {
                width: !noexp ? (width * ratio) + "px" : null,
                height: !noexp ? (height * ratio) + "px" : null
            }
        }
        else 
        {
            styles = {
                backgroundColor,
                transform: `translate(${x}px, ${y}px)`,
                width: `${width}px`,
                height: `${height}px`,
            }

            if (sendToBack) styles.zIndex = -1;

            innerStyles = {
                width: `${width}px`,
                height: `${height}px`,
            }

            imageProps = {
                width,
                height
            }
        }

        // check for author copy
        let aInfo = null;
        if (author) 
        {
            aInfo = {
                name: author.name,
                theme: author.theme,
                position: author.position ? author.position : "bottom-left",
                class: author.class ? author.class : "",
            }
        }

        const classItems = classNames("m-image-layer", classes, {});

        // check if lazyloaded
        let imgSrc = source;
        if (lazy && asset) imgSrc = !hasLazyLoaded ? phSrc : asset.src();

        return (
            <Fragment>
                <div className={classItems} style={styles}>
                    <div className="inner-image-layer" style={innerStyles}>
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                        {imgSrc && <img src={imgSrc} {...imageProps} style={imageStyles} alt={alt} tabIndex={0} />}
                        {aInfo && <div className={classNames("author-copy", aInfo.class)} data-theme={aInfo.theme} data-position={aInfo.position}>
                            <div className="author-by">{`Photo by ${aInfo.name}`}</div>    
                        </div>}
                        {newCh}
                    </div>
                </div>
            </Fragment>
        )
    }
}