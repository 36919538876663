/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Text Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import BackgroundVideo from "../../media/BackgroundVideo";

export default class VideoLayer extends Component
{
    static propTypes = {
        device: PropTypes.any,              // DeviceTool.screenSize
        type: PropTypes.string,
        classes: PropTypes.string,
        mobile: PropTypes.shape({
            order: PropTypes.number
        }),
        autoPlay: PropTypes.bool,
        source: PropTypes.string,
        asset: PropTypes.any,               // lazyload, string or object
        width: PropTypes.number,
        height: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        hasLazyLoaded: PropTypes.bool
    }
    
    static defaultProps = {
        type: "VideoLayer",
        classes: "",
        mobile: {},
        autoPlay: false,
        hasLazyLoaded: false
    }

    render()
    {
        const { 
            device,
            classes,
            mobile,
            backgroundColor,
            poster,
            source, asset, autoPlay,
            x, y, width, height,
            hasLazyLoaded,
            children
        } = this.props;

        let styles = {}, innerStyles = {};
        if (device.portrait)
        {
            styles = { backgroundColor }
            if (mobile && mobile.hasOwnProperty("order")) styles.order = mobile.order;
        }
        else 
        {
            styles = {
                backgroundColor,
                transform: `translate(${x}px, ${y}px)`,
                width: `${width}px`,
                height: `${height}px`,
            }

            innerStyles = {
                width: `${width}px`,
                height: `${height}px`,
            }
        }

        const classItems = classNames("m-video-layer", classes, {});

        // check if lazyloaded
        let imgSrc = source;
        if (asset) imgSrc = !hasLazyLoaded ? false : asset;

        return (
            <Fragment>
                <div className={classItems} style={styles}>
                    <div className="inner-video-layer" style={innerStyles}>
                        {imgSrc && <BackgroundVideo 
                            classes="video-layer"
                            autoPlay={autoPlay}
                            desktop={imgSrc}
                            poster={device.mobile ? poster : null}
                            standBy={device.mobile || device.os === "mac"}
                            scrnSize={false}
                            isBackground={true}
                            autoSize={false}
                        />}
                        {children}
                    </div>
                </div>
            </Fragment>
        )
    }
}