    /**
 *
 * Rehabilitation Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import VideoLayer from "../module/layers/VideoLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    rehabData, 
} from "../../data/PageAssets";

export default class RehabilitationPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "rehabilitation";         // asset folder for page
        this._assets = rehabData();                 // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz, hasResized: rz } = this.state;

        const getVid = this.pVideoNam("rehab_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        const videoConfig = { 
            offset: 20, 
            position: "bottom center", 
            phone: { 
                offset: 20, 
                position: "bottom center" 
            } 
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page rehab"}>
                    <div className={"page-content"}>
                        <h1>Rehabilitation</h1>
                        <p>Casa Lupita’s services are provided by highly trained professionals, including licensed 
                        physical therapists, and a Board-Certified Orthopedic Surgeon specializing in Pediatrics and Trauma.</p>
                        <h2>During your stay, we are able to offer:</h2>
                        <ul>
                            <li>Physical Therapy</li>
                            <li>Occupational Therapy </li>
                            <li>Strength and Stability training</li>
                            <li>Hydrotherapy, including special attention to swimming and water sports</li>
                            <li>Equino varo Treatment and follow up</li>
                            <li>Prosthetic fabrication and post-delivery follow up</li>
                            <li>Post surgery rehabilitation</li>
                            <li>Professional supervision and babysitting service</li>
                        </ul>
                        <p>Specialty tours and activities, including fishing excursion, archeological tours, as well as 
                        surfing and boogey boarding are available.</p>
                        <p>Casa Lupita’s team of professionals are experienced with the treatment and support of patients with Sequela of:</p>
                        <ul>
                            <li>Cerebral Palsy</li>
                            <li>Muscular Dystrophy</li>
                            <li>Musculoskeletal Deformity</li>
                            <li>Stroke Rehabilitation</li>
                            <li>Trauma rehabilitation</li>
                            <li>PEVAC / club foot rehabilitation by Ponseti Method</li>
                            <li>Autism</li>
                            <li>Pre and Post prosthetic rehabilitation</li>
                            <li>Amputation</li>
                        </ul>
                        <p>During your stay with us, the facility offers complete handicap accessibility and transportation, 
                        wheel chairs (both manual and electric), as well pediatric and adult walkers, crutches and shower chairs.</p>
                        <h2>Staff and Services</h2>
                        <p>Casa Lupita’s Bilingual Staff includes:</p>
                        <ul>
                            <li>Board Certified Orthopedic Surgeon, Specializing in Pediatrics and Trauma (Available on request 
                                for evaluation and therapeutic recommendations).</li>
                            <li>Licensed Physical Therapists trained in rehabilitation, prosthetic evaluations, strength and 
                                stability training, water therapy, postsurgical rehabilitation, swimming and surf training.</li>
                            <li>English to Spanish, Spanish to English language teacher</li>
                            <li>Professional baby sitter and care giver service</li>
                        </ul>
                        <h2>Additonal Services</h2>
                        <ul>
                            <li>Chef service</li>
                            <li>Concierge Services</li>
                            <li>Daily Maid service and nightly turndown</li>
                        </ul>
                        <p>Request more details from our concierge</p>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("rehab_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Rehabilitation"
                                    body={"Casa Lupita’s services are provided by highly trained professionals, including licensed physical therapists, and a Board-Certified Orthopedic Surgeon specializing in Pediatrics and Trauma."}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    classes="first-pad"
                                    alt="Trainer with girl at the beach"
                                    asset={this.assetRef("worker_with_girl_on_beach")}
                                    x={0} y={107} width={1920} height={1078}
                                />
                                
                                <TextLayer 
                                    classes="during-text"
                                    x={208} y={268} 
                                    width={980} height={920} 
                                    titleText={"During your stay, we are able to offer:"}
                                >
                                    <Fragment>
                                        <ul>
                                            <li>Physical Therapy</li>
                                            <li>Occupational Therapy </li>
                                            <li>Strength and Stability training</li>
                                            <li>Hydrotherapy, including special attention to swimming and water sports</li>
                                            <li>Equino varo Treatment and follow up</li>
                                            <li>Prosthetic fabrication and post-delivery follow up</li>
                                            <li>Post surgery rehabilitation</li>
                                            <li>Professional supervision and babysitting service</li>
                                        </ul>
                                    </Fragment>
                                </TextLayer>
                                
                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    classes="bottom-space"
                                    alt="Couple looking at island in the distance"
                                    asset={this.assetRef("couple_looking_at_island")}
                                    x={0} y={0} width={1920} height={1358}
                                />

                            </ModuleLayer>                            

                            <ModuleLayer backgroundColor="white" {...mProps} video={videoConfig}>

                                <ImageLayer 
                                    alt="Disabled girl working on crafts"
                                    asset={this.assetRef("girl_creating_crafts")}
                                    x={0} y={107} width={972} height={1311}
                                />
                                
                                <TextLayer classes="bottom-space" x={1090} y={150} width={665} height={1036}>

                                    <Fragment>
                                        Specialty tours and activities, including fishing excursion, archeological tours, as well as 
                                        surfing and boogey boarding are available.
                                        <p>Casa Lupita’s team of professionals are experienced with the treatment and support of patients with Sequela of:</p>
                                        <ul>
                                            <li>Cerebral Palsy</li>
                                            <li>Muscular Dystrophy</li>
                                            <li>Musculoskeletal Deformity</li>
                                            <li>Stroke Rehabilitation</li>
                                            <li>Trauma rehabilitation</li>
                                            <li>PEVAC / club foot rehabilitation by Ponseti Method</li>
                                            <li>Autism</li>
                                            <li>Pre and Post prosthetic rehabilitation</li>
                                            <li>Amputation</li>
                                        </ul>
                                    </Fragment>

                                </TextLayer>

                                {/*<ImageLayer 
                                    alt="Trainer adding a cast to disabled girl's left arm"
                                    asset={this.assetRef("trainer_adding_cast")}
                                    x={1071} y={943} width={846} height={476}
                                />*/}

                                
                                {<VideoLayer 
                                    hasLazyLoaded={lz}
                                    poster={this.assetRef("trainer_adding_cast").src()}
                                    asset={this.withPrefix("static/videos/minis/hd/rehab_846x476.mp4")}
                                    x={1071} y={943} width={846} height={476}
                                />}
                                
                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Wheelchair entering Casa Lupita van"
                                    asset={this.assetRef("wheelchair_entering_van")}
                                    x={0} y={0} width={1920} height={1079}
                                />

                            </ModuleLayer>    

                            <Module hasRichLayout={true} classes="during-stay" device={mProps.device}>
                                <ModuleContent>
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody classes="f-23" body={<>
                                                During your stay with us, the facility offers complete handicap accessibility and transportation, 
                                                wheel chairs (both manual and electric), as well pediatric and adult walkers, crutches and shower chairs.</>
                                            } />
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Trainers working with girl"
                                    asset={this.assetRef("trainers_working_with_girl")}
                                    x={0} y={0} width={1916} height={1080}
                                />

                            </ModuleLayer>    

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Inside main office area"
                                    asset={this.assetRef("inside_main_office_area")}
                                    x={1068} y={107} width={850} height={569}
                                />

                                <TextLayer classes="bottom-space" x={264} y={99} width={695} height={1036} titleHtml={"Staff and Services"}>

                                    <Fragment>
                                        <p>Casa Lupita’s Bilingual Staff includes:</p>
                                        <ul>
                                            <li>Board Certified Orthopedic Surgeon, Specializing in Pediatrics and Trauma (Available on request 
                                                for evaluation and therapeutic recommendations).</li>
                                            <li>Licensed Physical Therapists trained in rehabilitation, prosthetic evaluations, strength and 
                                                stability training, water therapy, postsurgical rehabilitation, swimming and surf training.</li>
                                            <li>English to Spanish, Spanish to English language teacher</li>
                                            <li>Professional baby sitter and care giver service</li>
                                        </ul>
                                    </Fragment>

                                </TextLayer>

                                <ImageLayer 
                                    alt="Trainers looking on as disabled girl surfs in pool"
                                    asset={this.assetRef("trainers_girl_surf_pool")}
                                    x={0} y={782} width={1920} height={1080}
                                />

                                <ImageLayer 
                                    alt="Dining table"
                                    asset={this.assetRef("dining_table")}
                                    x={162} y={1766} width={872} height={647}
                                />

                                <TextLayer classes="bottom-space" x={1146} y={1971} width={540} height={300} titleHtml={"Additional Services"}>
                                    <Fragment>
                                        <ul>
                                            <li>Chef service</li>
                                            <li>Concierge Services</li>
                                            <li>Daily Maid service and nightly turndown</li>
                                        </ul>
                                        <p>Request more details from our concierge</p>
                                    </Fragment>
                                </TextLayer>

                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}