/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { LayerType } from "./layers/LayerTypes";

export default class ModuleLayer extends Component
{
    static propTypes = {
        device: PropTypes.any.isRequired,                  // DeviceTool.screenSize
        className: PropTypes.string,
        theme: PropTypes.string,
        video: PropTypes.object,
        animate: PropTypes.string,
        backgroundColor: PropTypes.string,
        paddingBottom: PropTypes.number,
        hasResized: PropTypes.bool,
        ignoreTopPadding: PropTypes.bool,
        hasLazyLoaded: PropTypes.bool,
    }
    
    static defaultProps = {
        className: "",
        theme: "light",
        backgroundColor: "white",
        paddingBottom: 0,
        hasResized: false,
        ignoreTopPadding: false,
        hasLazyLoaded: false,
    }

    constructor(props)
    {
        super(props);

        this.refWidth = 1920;

        this.state = {
            width: 0,
            height: 0,
            x: 0,
            y: 0
        }
    }

    render()
    {
        const { 
            device,
            classes,
            theme,
            video,
            animate,
            backgroundColor,
            paddingBottom,
            ignoreTopPadding,
            children,
            hasLazyLoaded 
        } = this.props;

        // process layers
        const ratio = device.width / this.refWidth;
        let moduleHeight = 0, topYPosLayer = 0, bottomYPosLayer = 0;
        let _preLayers = [];

        if (Array.isArray(children)) _preLayers = children;
        else _preLayers = [children];
        
        const layers = _preLayers.map((layer, index) => {

            let newProps = { 
                x: layer.props.x * ratio,
                y: layer.props.y * ratio,
                width: layer.props.width * ratio,
                height: layer.props.height * ratio,
                device,
            }

            if (device.portrait)
            {
                newProps = {
                    hasLazyLoaded: hasLazyLoaded,
                    device
                }
            }
            else 
            {
                switch (layer.props.type)
                {
                    case LayerType.TEXT_LAYER:
                    case LayerType.BUTTON_LAYER:
                        newProps.fontSize = layer.props.fontSize * ratio;
                        newProps.textAlign = layer.props.textAlign;
                    break;
                    
                    case LayerType.IMAGE_LAYER:
                    case LayerType.VIDEO_LAYER:
                        newProps.hasLazyLoaded = hasLazyLoaded;
                    break;
                    
                    case LayerType.ELEMENT_LAYER:
                        delete newProps.width;
                        delete newProps.height;
                    break;
                    default:
                }
    
                if (topYPosLayer === 0) topYPosLayer = newProps.y;
                else {
                    if (newProps.y <= topYPosLayer) topYPosLayer = newProps.y;
                }
                
                if (bottomYPosLayer === 0) bottomYPosLayer = newProps.y + newProps.height;
                else {
                    if (newProps.y + newProps.height >= bottomYPosLayer) bottomYPosLayer = (newProps.y + newProps.height);
                }
            }

            return React.cloneElement(layer, { key: index, ...newProps });
        });

        let styles = {};
        if (!device.portrait)
        {
            moduleHeight = (topYPosLayer + bottomYPosLayer); // top most padding + bottom most layer + height

            if (ignoreTopPadding) moduleHeight -= topYPosLayer;
    
            // add padding
            moduleHeight += (paddingBottom * ratio);
    
            styles = {
                backgroundColor,
                transform: `translate(0 , 0)`,
                width: `${device.width}px`,
                height: `${moduleHeight}px`,
            }
        }
        else 
        {
            styles = { backgroundColor }
        }

        const classItems = classNames("module-layer", classes, {});

        return (
            <Fragment>
                <section className={classItems} style={styles} data-theme={theme} data-video={JSON.stringify(video)} data-animate={animate}>
                    {layers}
                </section>
            </Fragment>
        )
    }
}