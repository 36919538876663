/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module Text Layer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class TextLayer extends Component
{
    static propTypes = {
        device: PropTypes.any,              // DeviceTool.screenSize
        type: PropTypes.string,
        classes: PropTypes.string,
        mobile: PropTypes.shape({
            order: PropTypes.number
        }),
        backgroundColor: PropTypes.string,
        text: PropTypes.string,
        html: PropTypes.string,
        titleText: PropTypes.string,
        titleHtml: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        x: PropTypes.number,
        y: PropTypes.number,
        baseFontSize: PropTypes.number,
        fontSize: PropTypes.number,
        textAlign: PropTypes.string,
        lineHeight: PropTypes.string,
    }
    
    static defaultProps = {
        type: "TextLayer",
        classes: "",
        mobile: {},
        backgroundColor: null,
        text: null,
        titleText: null,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        baseFontSize: -1,
        fontSize: 100,
        textAlign: "left"
    }

    render()
    {
        const { 
            device,
            classes,
            mobile,
            children,
            backgroundColor,
            text, html, fontSize,
            baseFontSize,
            titleText, titleHtml,
            textAlign, lineHeight,
            x, y, width, height
        } = this.props;

        let styles = {}, innerStyles = {}, titleStyles = {};

        if (device.portrait)
        {
            styles = { backgroundColor }
            if (mobile && mobile.hasOwnProperty("order")) styles.order = mobile.order;
        }
        else 
        {
            styles = {
                backgroundColor,
                transform: `translate(${x}px, ${y}px)`,
                width: `${width}px`,
                height: `${height}px`,
                textAlign: textAlign,
            }

            if (baseFontSize !== -1) styles.fontSize = baseFontSize + "px";
            if (lineHeight) styles.lineHeight = lineHeight;

            innerStyles = {
                fontSize: `${fontSize}%`
            }

            titleStyles = {
                fontSize: `${fontSize}%`
            }
        }

        const classItems = classNames("m-text-layer", classes, {
        });

        const _html = html ? { __html: html } : null;
        const _titleHtml = titleHtml ? { __html: titleHtml } : null;

        return (
            <Fragment>
                <div className={classItems} style={styles}>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    {(_titleHtml) && <h3 className="text-title" tabIndex={0}>
                        <span className="inner-text-title" dangerouslySetInnerHTML={_titleHtml} style={titleStyles}></span>
                    </h3>}
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    {(titleText) && <h3 className="text-title" tabIndex={0}>
                        <span className="inner-text-title" style={titleStyles}>{titleText}</span>
                    </h3>}

                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    {_html && <div className="inner-text-layer" dangerouslySetInnerHTML={_html} style={innerStyles} tabIndex={0}></div>}
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    {(text || children) && <div className="inner-text-layer" style={innerStyles} tabIndex={0}>{text ? text : children}</div>}
                </div>
            </Fragment>
        )
    }
}